import $ from 'jquery'
import Swiper, { Navigation, Pagination, Autoplay, autoHeight, EffectFade, watchSlidesVisibility, watchSlidesProgress, freeMode, Thumbs} from 'swiper';

function init(options, container){
  
  container = container ? container : '.swiper-container';

  Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

  var swiper = new Swiper(container, options);
}

export default function initSlider(options, container){
 $(document).ready( init(options, container)  )
}