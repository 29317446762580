import $ from 'jquery'

const $doc = $(document)
const $body = $('body')
const $mobileNav = $('.js-mobile-nav')
const $hamburger = $('.js-hamburger')

function showMobileNav(event) {
  event.preventDefault()
  $mobileNav.toggleClass('is-active')
  $hamburger.toggleClass('is-active')
  $body.toggleClass('mobile-nav-active')
  if ( $mobileNav.hasClass('is-active')) {
    $hamburger.attr('aria-expanded', 'true')
  } else {
    $hamburger.attr('aria-expanded', 'false')
  }
}
function showMobileSubNav(event) {
  event.preventDefault()
  $(this).parent().toggleClass('is-active')
  if ( $(this).parent().hasClass('is-active')) {
    $('.js-mobile-nav .menu-item-has-children > .sub-menu').attr('aria-expanded', 'true')
  } else {
    $('.js-mobile-nav .menu-item-has-children > .sub-menu').attr('aria-expanded', 'false')
  }
}

export default function initMobileNav(){
  $doc.on('click', '.js-hamburger', showMobileNav )
  $doc.on('keydown', function(event) {
    if ( $mobileNav.hasClass('is-active') && (event.key == "Escape") ) {
      $hamburger.attr('aria-expanded', 'false')
      $mobileNav.toggleClass('is-active')
      $hamburger.toggleClass('is-active')
      $body.toggleClass('mobile-nav-active')
    }
  })
  $doc.on('click', '.js-mobile-nav .menu-item-has-children > a', showMobileSubNav )
  // $doc.on('focusin', function (event) {
  //   var $target = $(event.target);
  //   if (!$target.closest('.js-header-wrapper').length) {
  //     if ( $mobileNav.hasClass('is-active') ) {
  //       $hamburger.attr('aria-expanded', 'false')
  //       $mobileNav.toggleClass('is-active')
  //       $hamburger.toggleClass('is-active')
  //       $body.toggleClass('mobile-nav-active')
  //     }
  //   }
  // })
}
