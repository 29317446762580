import 'lazysizes'
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'magnific-popup';
import $ from 'jquery'
import initGreenSock      from '/lib/greensock'
import initHeadroom       from '/lib/headroom'
import initNav            from './lib/nav'
import initMobileNav      from '/lib/mobile-nav'
import { initCookies }    from '/lib/cookies'
import initBanner         from './lib/banner'
import initFooter         from "./lib/footer"
import initSlider         from "./lib/slider"
import initSkews          from "./lib/skews"
import initTeam           from './lib/team'
import initShowFormWide   from './lib/forms'

initGreenSock()
initHeadroom()
initNav()
initMobileNav()
initCookies()
initBanner()
initFooter()
initSkews()
initSlidersBySelector()
initTeam()
initShowFormWide()

function initSlidersBySelector() {
  $('.js-posts-slider').length && initSlider({
    spaceBetween: 0,
    slidesPerView: 1,
    centeredSlides: true,
    roundLengths: true,
    loop: true,
    loopAdditionalSlides: 30,
    observer: true,
    observeParents: true,
    parallax: true,
    simulateTouch: true,
    breakpoints: {
      700: {
        slidesPerView: 3,
      }
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  }, '.js-posts-slider')

  const eventsSliderOptions = {
    spaceBetween: 18,
    slidesPerView: 1,
    parallax: true,
    simulateTouch: true,
    speed: 600,
    breakpoints: {
      960: {
        slidesPerView: 3,
      },
      1180: {
        slidesPerView: 4,
      }
    },
    navigation: {
      nextEl: ".events-pagination__next",
      prevEl: ".events-pagination__previous"
    }
  }
  $('.js-events-slider').length && initSlider(eventsSliderOptions, '.js-events-slider')
  document.addEventListener('eventsUpdated', (e) => {
    initSlider(eventsSliderOptions, '.js-events-slider')
  });

  $('.js-hero-slider').length && initSlider({
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  }, '.js-hero-slider')

  $('.js-headlines').length && $(".js-headlines").each(function(){
    var $this = $(this);
    var blockindex = $(this).data("blockindex");
    var containerclass = `js-headlines-${blockindex}`;
    
    $this.addClass(containerclass);
    
    initSlider({
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: `.js-vertical-headlines-pagination-${blockindex}`,
        clickable: true,
          renderBullet: function (index, className) {
            return `<span class="vertical-headlines__pagination__item ${className}"> ${$(`.js-headlines-pagination-${blockindex}`)[0].children[index].innerHTML}</span>`;
          },
      }
    }, `.${containerclass}`)
  });

  $('.js-slider-history').length && initSlider({
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    pagination: {
      el: '.history-carousel__pagination',
			clickable: true,
        renderBullet: function (index, className) {
          return `<span class="vertical-headlines__pagination__item ${className}"> ${$('.js-slider-history-pagination')[0].children[index].innerHTML}</span>`;
        },
    },
  }, '.js-slider-history')
}

$(window).resize(function() {
  if(this.resizeTO) clearTimeout(this.resizeTO);
  this.resizeTO = setTimeout(function() {
    $(this).trigger('resizeEnd');
  }, 500);
});

$(window).bind('resizeEnd', function() {
  const swiper = $('.js-slider-history').length && document.querySelector('.js-slider-history').swiper;
  const params = swiper?.params;
  if (swiper) {
    swiper.destroy(true, true);
    $('.js-slider-history').length && initSlider(params, '.js-slider-history');
  }
});
