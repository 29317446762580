import $ from 'jquery'
import { setCookie, getCookie } from './cookies'

const $doc = $(document)
const $body = $('body')

export default function initBanner(){
  if ( !getCookie('banner') ) {
    $('.js-banner').removeClass('is-hidden')
    $body.addClass('has-banner')
  }

  $doc.on('click', '.js-banner .banner__close', (e) => {
    e.preventDefault()

    const { currentTarget } = e
    
    if ( currentTarget.dataset.timestamp ) {
      setCookie('banner', 1, currentTarget.data.timestamp)
    }
    $('.js-banner').addClass('is-hidden')
    $body.removeClass('has-banner')
  } )
}
