import $ from 'jquery'

const $doc = $(document)
const $win = $(window)

function showFormWide(event) {
  const $formWide = $('.js-form-wide')

  $formWide.toggleClass('is-active')
  if ( $formWide.hasClass('is-active')) {
    $(event.currentTarget).attr('aria-expanded', 'true')
  } else {
    $(event.currentTarget).attr('aria-expanded', 'false')
  }
}

function toggleLabels(e) {
  const elm = e.currentTarget

  addIsEmptyClass(elm)
}

function checkInputsOnLoad() {
  if ( $('.js-form-wide input, .js-form-compact input').length ) {
    $('.js-form-wide input, .js-form-compact input').each(function() {
      addIsEmptyClass(this)
      $(this).addClass('is-shown')
    })
  }
  if ( $('.js-form-wide').length ) {
    $('.js-form-wide .at-fields .at-row.PostalCode').insertAfter('.js-form-wide .at-fields .at-row.EmailAddress')
  }
}

function addIsEmptyClass(elm) {
  if ( elm.value ) {
    $(elm).addClass('is-not-empty')
  } else {
    $(elm).removeClass('is-not-empty')
  }
}

export default function initShowFormWide(){
  $win.on('load', checkInputsOnLoad )
  $doc.on('click', '.js-form-wide > span', showFormWide )
  $doc.on('blur', '.js-form-wide input, .js-form-compact input', toggleLabels )
  $doc.on('keydown', function(event) {
    const $formWide = $('.js-form-wide')

    if ( $formWide.hasClass('is-active') && (event.key == "Escape") ) {
      $formWideInner.attr('aria-expanded', 'false')
      $formWide.toggleClass('is-active')
    }
  })
}
