import $ from 'jquery'

const $doc = $(document)
const $win = $(window)
const $header = $('.js-header-nav:visible')


function init() {
  positionSubmenus()
  unhideSearch()
  appendGoogleTranslate()
  checkForID()
}

function checkForID() {
  if ( window.location.hash ) {
      const headerHeight = $header.outerHeight() 
      const $target = $(window.location.hash)
      $target.length && $('html, body').scrollTop( $target.offset().top - headerHeight )
  }
}

function scrollSmooth(e) {
  const headerHeight = $header.outerHeight()
  const { currentTarget } = e 
  if (
    location.pathname.replace(/^\//, '') == currentTarget.pathname.replace(/^\//, '')
    &&
    location.hostname == currentTarget.hostname
  ) {
    e.preventDefault()
    e.stopPropagation()
  
    console.log( currentTarget.pathname )
    var scrollTarget = $(currentTarget.hash);
    if (scrollTarget.length) {
      $('html, body').animate({
        scrollTop: scrollTarget.offset().top - headerHeight
      }, 500 );
    }
  }
}

function appendGoogleTranslate() {
  $('.header--full .js-language').html( $('#google-translate') )
}

function unhideSearch() {
  $('.header__search').removeClass('is-hidden');  
}

function toggleSearch(e) {
  e.preventDefault();
  $('.header__search').toggleClass('is-active');
  $('body').toggleClass('search-is-active');
  $('.search-field').focus();
}

function closeSearch(e) {
  $('.header__search').removeClass('is-active');
  $('body').removeClass('search-is-active');
}

function positionSubmenus() {
  $win.on('load', () => {
    $('.menu-item').each(function() {
      const {offsetWidth} = this
      const { marginRight } = window.getComputedStyle(this)
      const sub_menu = $(this).find('.sub-menu')
      const calculatedOffset = offsetWidth+parseInt(marginRight);
      // TODO -- properly calculate offset
      sub_menu.length && sub_menu[0].style.setProperty('--menu-item-offset', '-97px');
    })
  })
}

export default function initNav(){
  $doc.ready( init()  )
  $doc.on( 'click', '.js-search-toggle', (e) => toggleSearch(e)  )
  $doc.on( 'click', '.js-search-close', (e) => closeSearch(e)  )
  $doc.on( 'click', '.js-scroll-smooth, .js-scroll-smooth > a', (e) => scrollSmooth(e)  )
}
