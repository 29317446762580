import $ from 'jquery'

export default function initSkews(){
  const noSkewElement = !$('[data-skew]').length
  || ( $('[data-skew]').length && isNaN($('[data-skew]').data('skew')) );

  if ( noSkewElement ) {
    return false;
  }

  $('[data-skew]').each(function() {
    const angle = $(this).data('skew');
    const sign = Math.sign(angle) == -1 ? '' : '-';
    const angleRad = angle * Math.PI / 180;
    const magicNumber = Math.abs( Math.tan(angleRad) / 2 );
    this.style.setProperty('--angle', $(this).data('skew') + 'deg');
    this.style.setProperty('--magic-number', magicNumber);
    this.style.setProperty('--margin-space', `${sign}${(angle * 12)}px`);
    $(this).addClass('skewloaded')
  })
}