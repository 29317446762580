import $ from 'jquery'
const $doc = $(document)

export default function initFooter(){
  $doc.on('click','.js-donate', (e) => goToDonate(e))

  const goToDonate = function(e) {
    const value = $(e.currentTarget).data('value');
    const amount = value !== 'Other Amount' ? `?am=${value}` : ''
    let windowOpen = window.open();  
    windowOpen.opener = null;
    windowOpen.location = `/donate/${amount}`
  }
}