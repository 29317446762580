import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import initStatsCounter from './stats'

function animateSimple(elem) {
  gsap.to(elem, {
    duration: 0.5,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.2,
    delay: 0.3,
    overwrite: 'auto',
    onComplete: function() { afterAnimate(elem) }
  })
}

function afterAnimate(elem) {
  if ( $(elem).hasClass('stats') || $(elem).hasClass('stats-row') ) initStatsCounter('.stats__number')
}

function setupFadeInElement(elem){
  hide(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateSimple(elem) }
  })
}
function hide(elem) {
  gsap.set(elem, {autoAlpha: 0, y: 50})
}
function init(){
  gsap.registerPlugin(ScrollTrigger)
  gsap.utils.toArray(".js-fadein").forEach( setupFadeInElement )
}

export default function initGreenSock(){
  $(document).ready( init )
}
