import $ from 'jquery';

const $doc = $(document)

function toggleTeamBio(e) {
  e.preventDefault()

  const { currentTarget } = e;
  const $teamMember = $(currentTarget).closest('.post-item');
  const $memberBio = $teamMember.find('.bio-popup');
  const $memberBioInfo = $teamMember.find('.bio-info');
  const $bioContainer = $teamMember.closest('article').nextAll('.bio-container:first');
  const isDesktop = $(window).width() >= 960;
  const isNotActive = !$teamMember.hasClass('is-active');

  $('.bio-container').each(function() {
    const $elm = $(this)
    if ( $elm.hasClass('is-open') && $elm[0] !== $bioContainer[0] ) {
      $elm.hide()
    }
  });

  !isNotActive && $bioContainer.slideUp();
  $('.bio-popup').slideUp();

  $('.team .post-item').removeClass('is-active');
  isNotActive && $teamMember.addClass('is-active');
  
  if ( !isDesktop ) {
    isNotActive && $memberBio.slideDown();
    scrollTo$element($memberBioInfo);
  } else {
    isNotActive && $bioContainer.slideDown();
    $bioContainer.addClass('is-open');
    $bioContainer.html( $memberBio.html() );
  }
}

function scrollTo$element($elm) {
  if ($elm.length) {
    $('html, body').animate({
      scrollTop: $elm.offset().top
    }, 1000);
  }
}

export default function initTeam(){
  $doc.on('click', '.js-team-popup', toggleTeamBio )
}
