import $ from 'jquery';

export default function initStatsCounter(classname) {
  if ( !$(classname).length ) {
    return false
  }

  $(classname).each(function() {
    $(this).addClass('is-shown')

    const number = $(this).data('number').toString().replace(',', '')
    const stop = number.indexOf('.') !== -1 ? 0 : number

    if ( stop > 0 ) {

      let symbol = {}
      let increment = 1

      // Controls the speed based on how big the number is
      let count = stop > 500 ? Math.floor(stop * 0.50) : Math.floor(stop * 0.20)

      symbol.start = $(this).data('prepend') ? $(this).data('prepend') : '';
      symbol.end = $(this).data('append') ? $(this).data('append') : '';
      
      // Getting the number of zeros to create incrementing based on number length
      const zeros = Math.floor( Math.log10(stop) ) 
      for (let i = 0; i < zeros; i++) {
        increment = stop <= 10 ? 1 : increment * 10
      }
  
      this.innerHTML = addCommas(count.toString(), symbol);
      
      const statTimer = setInterval(() => {
  
        count += increment;
   
        if ( (stop - increment) <= count && (stop - increment) % count !== 0 ) {
          increment = increment / 10
        }
  
        this.innerHTML = symbol.start + addCommas(count.toString()) + symbol.end
  
        if (count >= stop) {
          clearInterval(statTimer)
        } 
      }, 100);

    }
  })
}

function addCommas(nStr){
  return parseInt(nStr).toLocaleString()
}
